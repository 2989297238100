import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: url(/images/column/top/bgColumnTop01.jpg);
  background-size: cover;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.6);
  background-blend-mode: lighten;
  h2 {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    margin: 10px 0 0;
    color: #153758;
    img {
      width: 70vw;
      max-width: 500px;
      display: block;
      margin: 0 auto 20px;
    }
  }
  ${BreakPoints.medium} {
    padding-top: 40px;
    h2 {
      font-size: 16px;
    }
  }
  ${BreakPoints.small} {
  }
`

/**
 * キンクラコラムTOPイメージ
 * @constructor
 */
const ColumnTopImage = (): JSX.Element => (
  <Wrapper>
    <h2>
      <img src="/images/column/top/logo.png" alt="キンクラコラム" />
      勤怠管理に関する知識や役に立つ情報をお届けします
    </h2>
  </Wrapper>
)

export default ColumnTopImage
